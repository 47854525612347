import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
const IndexPage = () => (
  <Layout>
    <div class="overflow-x-hidden bg-gradient-to-b from-blue-700 ">
      <div>
        <div class="max-w-7xl  container flex justify-between mx-auto radius-md">
          <div class="container overflow-x-hidden  ">
            <div class="text-xl text-center  p-10 justify-center">
              <h1 class="text-xl text-white text-center  p-4 justify-center text-5xl	">
                Staff
              </h1>
              <div class="px-10 py-20 bg-white grid gap-10 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-2">
                <div class="max-w-xs rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer">
                  <div>
                    <StaticImage
                      src="../images/pastor.png"
                      className="rounded-md"
                      alt="Pastor"
                    />
                  </div>
                  <div class="py-4 px-4 bg-white">
                    <h3 class="text-md font-semibold text-gray-600">
                      First Name
                    </h3>
                    <p class="mt-4 text-lg font-thin">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour
                    </p>
                  </div>
                </div>
                <div class="max-w-xs rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer">
                  <div>
                    <StaticImage
                      src="../images/pastor.png"
                      className="rounded-md"
                      alt="Pastor"
                    />
                  </div>
                  <div class="py-4 px-4 bg-white">
                    <h3 class="text-md font-semibold text-gray-600">
                      First Name
                    </h3>
                    <p class="mt-4 text-lg font-thin">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour
                    </p>
                  </div>
                </div>
                <div class="max-w-xs rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer">
                  <div>
                    <StaticImage
                      src="../images/pastor.png"
                      className="rounded-md"
                      alt="Pastor"
                    />
                  </div>
                  <div class="py-4 px-4 bg-white">
                    <h3 class="text-md font-semibold text-gray-600">
                      First Name
                    </h3>
                    <p class="mt-4 text-lg font-thin">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour
                    </p>
                  </div>
                </div>
                <div class="max-w-xs rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer">
                  <div>
                    <StaticImage
                      src="../images/pastor.png"
                      className="rounded-md"
                      alt="Pastor"
                    />
                  </div>
                  <div class="py-4 px-4 bg-white">
                    <h3 class="text-md font-semibold text-gray-600">
                      First Name
                    </h3>
                    <p class="mt-4 text-lg font-thin">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour
                    </p>
                  </div>
                </div>
                <div class="max-w-xs rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer">
                  <div>
                    <StaticImage
                      src="../images/pastor.png"
                      className="rounded-md"
                      alt="Pastor"
                    />
                  </div>
                  <div class="py-4 px-4 bg-white">
                    <h3 class="text-md font-semibold text-gray-600">
                      First Name
                    </h3>
                    <p class="mt-4 text-lg font-thin">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour
                    </p>
                  </div>
                </div>{" "}
                <div class="max-w-xs rounded-md overflow-hidden shadow-lg hover:scale-105 transition duration-500 cursor-pointer">
                  <div>
                    <StaticImage
                      src="../images/pastor.png"
                      className="rounded-md"
                      alt="Pastor"
                    />
                  </div>
                  <div class="py-4 px-4 bg-white">
                    <h3 class="text-md font-semibold text-gray-600">
                      First Name
                    </h3>
                    <p class="mt-4 text-lg font-thin">
                      There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
